import SuccessView from "./SuccessView";
import { useSessionContext } from "supertokens-auth-react/recipe/session";
import "./Home.css";
import { useEffect } from "react";
import axios from "axios";

export default function Home() {
  const sessionContext = useSessionContext();

  // TODO: 修改域名
  useEffect(() => {
    if (!sessionContext.loading) {
      axios.get("http://browser-account.sdqma.com/api/sessioninfo").then((data) => {
        (window as any).login.postMessage(data.data.data.accessToken);
      });
    }
  }, [sessionContext]);

  if (sessionContext.loading === true) {
    return null;
  }

  return (
    <div className="fill" id="home-container">
      <SuccessView userId={sessionContext.userId} />
    </div>
  );
}
